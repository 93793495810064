import { generateScopedName, generateScopedPath } from '@libero/utilities/generic-routing';
import type { RouteRecordRaw } from 'vue-router';

export const generateRoleRoutes = (key: string): RouteRecordRaw[] => [
  {
    path: generateScopedPath('create', 'role', key),
    name: generateScopedName('role.create', 'role', key),
    component: () => import('./views/modals/Create.vue'),
    props: { scope: key },
    meta: {
      delay: true,
      permission: 'role.store',
    },
  },
  {
    path: generateScopedPath(':id', 'role', key),
    component: () => import('./views/modals/Show.vue'),
    props: { scope: key },
    children: [
      {
        path: 'properties',
        name: generateScopedName('role.show', 'role', key),
        component: () => import('./views/tabs/Properties.vue'),
      },
      {
        path: 'properties/edit',
        name: generateScopedName('role.edit', 'role', key),
        component: () => import('./views/tabs/PropertiesEdit.vue'),
      },
      {
        path: 'log',
        name: generateScopedName('role.log', 'role', key),
        component: () => import('./views/tabs/Log.vue'),
      },
      {
        path: '',
        redirect: { name: generateScopedName('role.show', 'role', key) },
      },
    ],
    meta: {
      delay: true,
      permission: 'role.show',
    },
  },
];

export const routes: RouteRecordRaw[] = [
  {
    path: 'role',
    name: 'role.index',
    component: () => import('./views/pages/Index.vue'),
    children: [...generateRoleRoutes('role')],
    meta: {
      title: 'Systeem instellingen',
      permission: 'role.index',
    },
  },
  {
    path: 'role/trashed',
    name: 'role.trash',
    component: () => import('./views/pages/Trashed.vue'),
    meta: {
      title: 'Systeem instellingen',
      permission: 'role.index',
    },
  },
];
