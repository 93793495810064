<script setup lang="ts">
import Badge from '@libero/ui-framework/Badge/Badge.vue';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import { computed } from 'vue';
import type { RouterLocation } from 'vue-router';

interface Props {
  to?: RouterLocation;
  type?: 'button' | 'submit' | 'reset';
  size?: 'xs' | 'sm' | 'md' | 'lg';
  color?: 'primary' | 'primary-light' | 'secondary' | 'secondary-light' | 'error';
  appearance?: 'dark' | 'light' | 'outline' | 'link' | 'clear';
  isDisabled?: boolean;
  hasBadge?: boolean;
  shouldOpenBlank?: boolean;
  onClick?: (event: Event) => void;
}

const props = withDefaults(defineProps<Props>(), {
  to: undefined,
  type: 'button',
  size: 'md',
  color: 'primary',
  appearance: 'dark',
  onClick: undefined,
});

const classes = computed(() => {
  return {
    ['icon-button']: true,
    ['icon-button--' + props.size]: true,
    ['icon-button--' + props.color]: true,
    ['icon-button--' + props.appearance]: true,
    ['has-badge']: props.hasBadge,
  };
});
</script>

<template>
  <Clickable
    :to="to"
    :type="type"
    :class="classes"
    :disabled="isDisabled"
    :shouldOpenBlank="shouldOpenBlank"
    :onClick="onClick"
  >
    <div class="icon-button__icon">
      <Badge v-if="hasBadge" class="icon-button-badge" />

      <slot />
    </div>
  </Clickable>
</template>

<style lang="scss">
@import '@libero/ui-framework/IconButton/IconButton.scss';
</style>
