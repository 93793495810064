import type { Resource } from '@libero/api-client/types/resource';
import type { ResourceScoutApi } from '@libero/api-client/types/resource-api';
import { jsonApi } from '@libero/utilities/api-client';
import QueryString from 'qs';

import type { ScoutOption, ScoutQuery, SearchItem } from './types';

const scout = jsonApi('api/scout');

export const generateResourceScoutApi = <T = Resource>(
  resourceName: string,
  mapItem: (item: T) => ScoutOption,
): ResourceScoutApi<T> => {
  const resourceScout = jsonApi(`api/${resourceName}`);

  const index = (query?: ScoutQuery): Promise<T[]> => {
    return resourceScout.post<T[]>('scout', query);
  };

  const options = async (query?: ScoutQuery): Promise<ScoutOption<T>[]> => {
    return index(query).then((items) => items.map((item) => ({ ...mapItem(item), item })));
  };

  return {
    index,
    options,
  };
};

const index = (query: string): Promise<SearchItem[]> => {
  return scout.get(undefined, {
    searchParams: QueryString.stringify({
      query,
    }),
  });
};

export const scoutApi = {
  name: 'scout',
  index,
};
