<script setup lang="ts">
import {
  ArrowDownTrayIcon,
  EllipsisVerticalIcon,
  EyeIcon,
  TrashIcon,
} from '@heroicons/vue/24/solid';
import type { Media } from '@libero/api-client/media/types';
import { ResourceApi } from '@libero/api-client/types/resource-api';
import DestroyModal from '@libero/organisms/DestroyModal/DestroyModal.vue';
import Dropdown from '@libero/ui-framework/Dropdown/Dropdown.vue';
import DropdownAction from '@libero/ui-framework/Dropdown/DropdownAction.vue';
import DropdownBody from '@libero/ui-framework/Dropdown/DropdownBody.vue';
import DropdownFooter from '@libero/ui-framework/Dropdown/DropdownFooter.vue';
import Icon from '@libero/ui-framework/Icon/Icon.vue';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import PreviewModal from '@libero/ui-framework/PreviewModal/PreviewModal.vue';
import {
  downloadMedia,
  getMediaExtension,
  getMediaIcon,
  getMediaSize,
} from '@libero/utilities/media';
import { QueryKey } from '@tanstack/vue-query';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  media: Media;
  resourceApi?: Pick<ResourceApi, 'name' | 'parentName' | 'parentId' | 'destroy'>;
  invalidateKeys?: QueryKey[];
  onRemove?: (id: string) => void;
}

const props = withDefaults(defineProps<Props>(), {
  resourceApi: undefined,
  invalidateKeys: undefined,
  onRemove: undefined,
});

const { t } = useI18n();

const handleRemove = () => {
  props.onRemove?.(props.media.uuid);
};

const icon = computed(() => getMediaIcon(props.media));
</script>

<template>
  <div v-if="media" class="media-item">
    <div class="media-item-content">
      <Icon size="xl">
        <component :is="icon" />
      </Icon>

      <div class="media-item-info">
        <span class="media-item-type">{{ getMediaExtension(media) }}</span>
        <span class="media-item-size">{{ getMediaSize(media) }}</span>
      </div>

      <span class="media-item-name">
        {{ media.file_name || media.name }}
      </span>
    </div>

    <Dropdown>
      <template #trigger>
        <IconButton size="sm" appearance="link" color="secondary">
          <EllipsisVerticalIcon />
        </IconButton>
      </template>

      <template #content>
        <DropdownBody>
          <PreviewModal v-slot="{ openModal }" :media="media">
            <DropdownAction :onClick="openModal">
              <EyeIcon class="dropdown-action-icon" />
              {{ t('preview') }}
            </DropdownAction>
          </PreviewModal>

          <DropdownAction :onClick="() => downloadMedia(media)">
            <ArrowDownTrayIcon class="dropdown-action-icon" />
            {{ t('download') }}
          </DropdownAction>
        </DropdownBody>

        <DropdownFooter v-if="resourceApi || onRemove">
          <DestroyModal
            v-if="resourceApi"
            v-slot="{ openModal }"
            :subject="media.file_name"
            :item="media"
            :resourceApi="resourceApi"
            :invalidateKeys="[
              ...(invalidateKeys || []),
              [`${resourceApi.parentName}.show`, resourceApi.parentId],
              [`${resourceApi.parentName}.activity`, resourceApi.parentId],
            ]"
          >
            <DropdownAction color="error" :onClick="openModal">
              <TrashIcon class="dropdown-action-icon" />
              {{ t('destroy') }}
            </DropdownAction>
          </DestroyModal>

          <DropdownAction v-else color="error" :onClick="handleRemove">
            <TrashIcon class="dropdown-action-icon" />
            {{ t('destroy') }}
          </DropdownAction>
        </DropdownFooter>
      </template>
    </Dropdown>
  </div>
</template>

<style lang="scss" scoped>
@import '@libero/organisms/MediaItem/MediaItem.scss';
</style>
