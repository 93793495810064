import {
  ArrowTrendingUpIcon,
  BookOpenIcon,
  BuildingOfficeIcon,
  CubeIcon,
  CubeTransparentIcon,
  DocumentIcon,
  DocumentTextIcon,
  EyeIcon,
  FireIcon,
  GlobeAltIcon,
  InboxIcon,
  KeyIcon,
  ListBulletIcon,
  LockOpenIcon,
  ShareIcon,
  Squares2X2Icon,
  TagIcon,
  UserIcon,
} from '@heroicons/vue/24/solid';
import { Attribute } from '@libero/api-client/attribute/types';
import { AttributeType } from '@libero/api-client/attribute-type/types';
import { Cause } from '@libero/api-client/cause/types';
import { Classification } from '@libero/api-client/classification/types';
import { Container } from '@libero/api-client/container/types';
import { Contract } from '@libero/api-client/contract/types';
import { ContractItem } from '@libero/api-client/contract-item/types';
import { Document } from '@libero/api-client/document/types';
import { Entity } from '@libero/api-client/entity/types';
import { EntityType } from '@libero/api-client/entity-type/types';
import { ExternalEndpoint } from '@libero/api-client/external-endpoint/types';
import { FileDocument } from '@libero/api-client/file/types';
import { FolderDocument } from '@libero/api-client/folder/types';
import { Integration } from '@libero/api-client/integration/types';
import { LeadTime } from '@libero/api-client/lead-time/types';
import { Organisation } from '@libero/api-client/organisation/types';
import { Permission } from '@libero/api-client/permission/types';
import { PriceList } from '@libero/api-client/price-list/types';
import { Product } from '@libero/api-client/product/types';
import { Relation } from '@libero/api-client/relation/types';
import { Role } from '@libero/api-client/role/types';
import { TermSheet } from '@libero/api-client/term-sheet/types';
import { Theme } from '@libero/api-client/theme/types';
import { Ticket } from '@libero/api-client/ticket/types';
import { TicketStatus } from '@libero/api-client/ticket-status/types';
import { TicketSubject } from '@libero/api-client/ticket-subject/types';
import { TicketType } from '@libero/api-client/ticket-type/types';
import { Resource } from '@libero/api-client/types/resource';
import { User } from '@libero/api-client/user/types';
import { View } from '@libero/api-client/view/types';
import { ProductIcon } from '@libero/theme/icons/custom';
import { kebabCase } from 'lodash';
import { FunctionalComponent } from 'vue';
import { RouterLocation } from 'vue-router';

import { getContainerAttributeValue } from './attributes';

export const getResourceName = (type: string): string => {
  return kebabCase(type?.split('\\').pop());
};

export const getResourceIcon = (name: string): FunctionalComponent | undefined => {
  switch (name) {
    case 'theme':
      return Squares2X2Icon;
    case 'container':
      return CubeIcon;
    case 'ticket':
      return InboxIcon;
    case 'ticket-subject':
      return BookOpenIcon;
    case 'ticket-type':
      return TagIcon;
    case 'ticket-status':
      return ArrowTrendingUpIcon;
    case 'relation':
      return ShareIcon;
    case 'organisation':
      return BuildingOfficeIcon;
    case 'role':
      return KeyIcon;
    case 'permission':
      return LockOpenIcon;
    case 'user':
      return UserIcon;
    case 'view':
      return EyeIcon;
    case 'selection':
      return ListBulletIcon;
    case 'attribute':
    case 'attribute-type':
      return TagIcon;
    case 'entity':
    case 'entity-type':
      return CubeTransparentIcon;
    case 'document':
      return DocumentIcon;
    case 'external-endpoint':
      return GlobeAltIcon;
    case 'contract':
      return DocumentTextIcon;
    case 'product':
      return ProductIcon;
    case 'cause':
      return FireIcon;
    default:
      return;
  }
};

export const getResourceSubject = (
  name: string,
  resource?: Resource | null,
): string | undefined => {
  if (!resource) return;

  switch (name) {
    case 'theme':
      return (resource as Theme).name;
    case 'container': {
      const container = resource as Container;
      const publicSpaceName = getContainerAttributeValue(container, 'public-space-name', true);

      return [publicSpaceName, container.name].filter(Boolean).join(' ');
    }
    case 'ticket':
      return (resource as Ticket).number.toString();
    case 'ticket-subject':
      return (resource as TicketSubject).name;
    case 'ticket-type':
      return (resource as TicketType).name;
    case 'ticket-status':
      return (resource as TicketStatus).name;
    case 'relation': {
      const relation = resource as Relation;
      const organisationName = relation.organisation?.name;
      const name = relation.name;
      const reference = relation.reference;

      return [relation?.type.name, organisationName, name, reference].filter(Boolean).join(' ');
    }
    case 'organisation':
      return (resource as Organisation).name;
    case 'role':
      return (resource as Role).name;
    case 'permission':
      return (resource as Permission).localized_name;
    case 'user':
      return (resource as User).full_name;
    case 'view':
      return (resource as View).name;
    case 'attribute':
      return (resource as Attribute).attribute_type.name;
    case 'attribute-type':
      return (resource as AttributeType).name;
    case 'entity':
      return (resource as Entity).entity_type.name;
    case 'entity-type':
      return (resource as EntityType).name;
    case 'document':
      return (resource as Document).name;
    case 'external-endpoint':
      return (resource as ExternalEndpoint).name;
    case 'contract':
      return (resource as Contract).name;
    case 'contract-item':
      return [(resource as ContractItem).number?.toString(), (resource as ContractItem).description]
        .filter(Boolean)
        .join(' - ');
    case 'term-sheet':
      return (resource as TermSheet).name;
    case 'lead-time':
      return (resource as LeadTime).name;
    case 'price-list':
      return (resource as PriceList).name;
    case 'product':
      return (resource as Product).name;
    case 'cause':
      return (resource as Cause).name;
    case 'integration':
      return (resource as Integration).name;
    case 'classification': {
      const classification = resource as Classification;

      return [classification.first_name, classification.last_name].filter(Boolean).join(' ');
    }
    default:
      return;
  }
};

export const getResourceShowRoute = (
  name: string,
  resource?: Resource,
): RouterLocation | undefined => {
  if (!resource) return;

  switch (name) {
    case 'theme':
    case 'permission':
    case 'external-endpoint':
    case 'entity':
    case 'ticket-type':
    case 'ticket-status':
    case 'price-list-item':
      return;

    case 'document': {
      const base = (resource as Document).file ? 'document.file' : 'document.folder';

      const id = (resource as Document).file
        ? (resource as FileDocument).file.id
        : (resource as FolderDocument).folder.id;

      return {
        name: `${base}.show`,
        params: { id },
      };
    }

    default:
      return {
        name: `${kebabCase(name)}.show`,
        params: { id: resource.id },
      };
  }
};
