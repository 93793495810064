<script setup lang="ts">
import { ChevronDoubleLeftIcon } from '@heroicons/vue/24/solid';
import Navigation from '@libero/cityview/modules/app/views/segments/Navigation/Navigation.vue';
import Title from '@libero/organisms/Title/Title.vue';
import CityviewLogo from '@libero/theme/logos/cityview.svg';
import IconButton from '@libero/ui-framework/IconButton/IconButton.vue';
import Sidebar from '@libero/ui-framework/Sidebar/Sidebar.vue';
import Tooltip from '@libero/ui-framework/Tooltip/Tooltip.vue';
import { classNames } from '@libero/utilities/class-names';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

<template>
  <Sidebar v-slot="{ isExpanded, toggleSidebar }">
    <Title size="sm" :class="classNames({ isExpanded })" link="/" shouldExpand />

    <Navigation :expanded="isExpanded" :class="classNames({ isExpanded })" />

    <div class="sidebar__footer">
      <div class="sidebar__brand">
        <CityviewLogo class="sidebar__logo" />
      </div>

      <div class="sidebar__toggle">
        <Tooltip :content="isExpanded ? t('close') : t('open')">
          <IconButton size="sm" appearance="link" color="secondary" :onClick="toggleSidebar">
            <ChevronDoubleLeftIcon />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  </Sidebar>
</template>

<style lang="scss" scoped>
@import '@libero/cityview/modules/app/views/segments/Sidebar/Sidebar.scss';
</style>
