<script setup lang="ts">
import type { Media } from '@libero/api-client/media/types';
import FileUpload from '@libero/organisms/FileUpload/FileUpload.vue';
import MediaItem from '@libero/organisms/MediaItem/MediaItem.vue';
import InputError from '@libero/ui-framework/InputError/InputError.vue';
import Stack from '@libero/ui-framework/Stack/Stack.vue';

interface Props {
  id: string;
  name: string;
  value: Media[];
  disabled?: boolean;
  error?: string;
  onUploaded?: () => void;
  onRemoved?: () => void;
  onUpdate: (value: Media[]) => void;
}

const props = defineProps<Props>();

const handleRemove = (id: string) => {
  props.onUpdate(props.value.filter((item) => item.uuid !== id));
  props.onRemoved?.();
};
</script>

<template>
  <Stack :gap="0.5">
    <FileUpload
      :id="id"
      :name="name"
      :value="value"
      :disabled="disabled"
      hasMultiple
      :onUploaded="onUploaded"
      :onUpdate="onUpdate"
    />

    <Stack :gap="0">
      <MediaItem v-for="item in value" :key="item.uuid" :media="item" :onRemove="handleRemove" />
      <InputError :message="error" />
    </Stack>
  </Stack>
</template>
