import { generateContainerRoutes } from '@libero/cityview/modules/container/generate-routes';
import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

import { generateTicketRoutes } from './generate-routes';

export const routes: RouteRecordRaw[] = [
  {
    path: 'ticket',
    name: 'ticket.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      ...generateTicketRoutes('ticket'),
      ...generateContainerRoutes('ticket'),
      ...generateFileRoutes('ticket'),
    ],
    meta: {
      title: 'Tickets',
      permission: 'ticket.index',
    },
  },
  {
    path: 'ticket/trashed',
    name: 'ticket.trash',
    component: () => import('./views/pages/Trashed.vue'),
    meta: {
      title: 'Tickets',
      permission: 'ticket.index',
    },
  },
];
