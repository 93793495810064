<script setup lang="ts">
import { useTenantStore } from '@libero/hooks/store/useTenantStore';
import Clickable from '@libero/ui-framework/Clickable/Clickable.vue';
import Image from '@libero/ui-framework/Image/Image.vue';
import { classNames } from '@libero/utilities/class-names';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface Props {
  title?: string;
  link?: string;
  size?: 'sm' | 'md' | 'lg';
  shouldExpand?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: undefined,
  link: '/',
  size: 'md',
});

const { t } = useI18n();

const tenantStore = useTenantStore();

const type = computed(() =>
  tenantStore.tenant?.type ? t(`tenant.types.${tenantStore.tenant?.type}`) : undefined,
);

const prefix = computed(() =>
  props.title ? [type.value, tenantStore.tenant?.name].filter(Boolean).join(' ') : type.value,
);

const title = computed(() => props.title || tenantStore.tenant?.name);
</script>

<template>
  <Clickable
    v-if="tenantStore.tenant"
    :to="link"
    class="tenant"
    :class="classNames({ size, shouldExpand })"
  >
    <div class="tenant__logo-wrap">
      <Image :url="tenantStore.tenant.logoPath" class="tenant__logo" />
    </div>

    <div class="tenant__text">
      <span class="tenant__prefix">{{ prefix }}</span>
      <span class="tenant__name">{{ title }}</span>
    </div>
  </Clickable>
</template>

<style lang="scss" scoped>
@import '@libero/organisms/Title/Title.scss';
</style>
