<script setup lang="ts">
import { trackEvent } from '@libero/utilities/analytics';
import { classNames } from '@libero/utilities/class-names';
import { isString } from 'lodash';
import { computed, ref } from 'vue';
import {
  RouteLocation,
  RouteLocationRaw,
  RouterLink,
  type RouterLocation,
  useRouter,
} from 'vue-router';

interface Props {
  href?: string;
  to?: RouterLocation;
  type?: 'submit' | 'button' | 'reset';
  isDisabled?: boolean;
  hasHover?: boolean;
  shouldOpenBlank?: boolean;
  onClick?: (event: Event) => void;
}

const props = withDefaults(defineProps<Props>(), {
  href: undefined,
  to: undefined,
  type: 'button',
  onClick: undefined,
});

const router = useRouter();

const button = ref<HTMLElement>();

const component = computed(() => {
  if (props.href) return 'a';
  if (props.shouldOpenBlank) return 'button';
  if (props.to) return RouterLink;
  if (props.onClick) return 'button';
  if (props.type === 'submit') return 'button';
  return 'div';
});

const handleBlank = (event: Event) => {
  event.preventDefault();

  if (props.href) {
    window.open(props.href, '_blank');
  }

  if (props.to) {
    const route = router.resolve(props.to as RouteLocationRaw);
    window.open(route.href, '_blank');
  }
};

const handleClick = (event: Event) => {
  if (props.to) {
    trackEvent({
      category: 'Navigation',
      action: 'click',
      label: isString(props.to)
        ? props.to
        : (((props.to as RouteLocation).name || 'unknown') as string),
    });
  } else {
    trackEvent({
      category: 'Navigation',
      action: 'click',
      label: button.value?.textContent || 'unknown',
    });
  }

  if (props.shouldOpenBlank) {
    return handleBlank(event);
  }

  return props.onClick?.(event);
};
</script>

<template>
  <component
    :is="component"
    ref="button"
    :href="href"
    :to="shouldOpenBlank ? undefined : to"
    :class="component !== 'div' ? classNames({ clickable: true, isDisabled, hasHover }) : undefined"
    :type="component === 'button' ? type : undefined"
    :disabled="component === 'button' ? isDisabled : undefined"
    :onClick="handleClick"
  >
    <slot />
  </component>
</template>

<style lang="scss">
@import '@libero/ui-framework/Clickable/Clickable.scss';
</style>
