import { pick } from 'lodash';
import QueryString from 'qs';
import type { LocationQuery, LocationQueryRaw, RouterOptions } from 'vue-router';

export const defaultRouterOptions: Partial<RouterOptions> = {
  parseQuery: (value: string) => {
    return QueryString.parse(value) as LocationQuery;
  },
  stringifyQuery: (query: LocationQueryRaw) => {
    const sortedQuery = pick(query, ['per_page', 'page', 'sort', ...Object.keys(query).sort()]);
    return QueryString.stringify(sortedQuery, { encode: false }) as string;
  },
};
