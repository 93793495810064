import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

import { generateOrganisationRoutes } from './generate-routes';

export const routes: RouteRecordRaw[] = [
  {
    path: 'organisation',
    name: 'organisation.index',
    component: () => import('./views/pages/Index.vue'),
    children: [
      ...generateOrganisationRoutes('organisation'),
      ...generateFileRoutes('organisation'),
    ],
    meta: {
      title: 'Systeem instellingen',
      permission: 'organisation.index',
    },
  },
  {
    path: 'organisation/trashed',
    name: 'organisation.trash',
    component: () => import('./views/pages/Trashed.vue'),
    meta: {
      title: 'Systeem instellingen',
      permission: 'organisation.index',
    },
  },
];
