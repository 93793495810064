import { generateFileRoutes } from '@libero/cityview/modules/file/generate-routes';
import type { RouteRecordRaw } from 'vue-router';

import { generateUserRoutes } from './generate-routes';

export const routes: RouteRecordRaw[] = [
  {
    path: 'user',
    name: 'user.index',
    component: () => import('./views/pages/Index.vue'),
    children: [...generateUserRoutes('user'), ...generateFileRoutes('user')],
    meta: {
      title: 'Systeem instellingen',
      permission: 'user.index',
    },
  },
  {
    path: 'user/trashed',
    name: 'user.trashed',
    component: () => import('./views/pages/Trashed.vue'),
    meta: {
      title: 'Systeem instellingen',
      permission: 'user.index',
    },
  },
];
