import type { User } from '@libero/api-client/user/types';
import { jsonApi } from '@libero/utilities/api-client';

import type {
  AcceptTermsRequest,
  ChangePasswordRequest,
  ForgotPasswordRequest,
  ForgotPasswordResponse,
  LoginRequest,
  LoginResponse,
  ProviderLoginResponse,
  RegisterRequest,
  RegisterResendEmailRequest,
  ResetPasswordRequest,
} from './types';

const auth = jsonApi('api/auth');

const login = (data: LoginRequest): Promise<LoginResponse> => {
  return auth.post('login', data);
};

const logout = (): Promise<unknown> => {
  return auth.post('logout');
};

const register = (data: RegisterRequest): Promise<unknown> => {
  return auth.post('register', data);
};

const registerResendEmail = (data: RegisterResendEmailRequest): Promise<unknown> => {
  return auth.post('register/resend-email-verification', data);
};

const forgotPassword = (data: ForgotPasswordRequest): Promise<ForgotPasswordResponse> => {
  return auth.post('forgot-password', data);
};

const resetPassword = (data: ResetPasswordRequest): Promise<unknown> => {
  return auth.post('reset-password', data);
};

const changePassword = (data: ChangePasswordRequest): Promise<unknown> => {
  return auth.put('user/password', data);
};

const providerLogin = async (): Promise<ProviderLoginResponse> => {
  return auth.get('microsoft/redirect');
};

const providerVerify = async (search: string): Promise<LoginResponse> => {
  return auth.post(`microsoft/callback${search}`);
};

const acceptTerms = async (data: AcceptTermsRequest): Promise<unknown> => {
  return auth.put('user/accept/terms-and-conditions', data);
};

const user = (): Promise<User> => {
  return auth.get('user');
};

export const authApi = {
  login,
  logout,
  register,
  registerResendEmail,
  forgotPassword,
  resetPassword,
  changePassword,
  providerLogin,
  providerVerify,
  acceptTerms,
  user,
};
